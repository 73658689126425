import * as THREE from 'three';
import { getPointInBetweenByLen} from './nick-utils.js';

class UI{
	constructor(options){
		this.room = options;
		
		window.addEventListener( 'mousemove', this.onMouseMove, false );
		window.addEventListener( 'mouseup', this.onMouseUp, false );
		window.addEventListener( 'mousedown', this.onMouseDown, false );

		window.addEventListener( 'touchmove', this.onTouchMove, false);
		window.addEventListener( 'touchstart', this.onMouseDown, false);
		window.addEventListener( 'touchend', this.onMouseUp, false);

		window.addEventListener("orientationchange",this.onWindowResize);
		window.addEventListener("resize", this.onWindowResize, false);
		
		

	}

	/**
	 * resets viewport display on viewport resize event
	 * @return null
	 */
	onWindowResize() {
		console.log("RESIZE")
		console.log( this)
		console.log( this.room.cameras)
		const width = Math.min(document.documentElement.clientWidth, window.innerWidth);
		const height = Math.min(document.documentElement.clientHeight, window.innerHeight);
		this.room.cameras[0].aspect = width / height;
		this.room.renderer.setSize( width, height);
		this.room.cameras[0].updateProjectionMatrix();
		
		
	}
	/**
	 * method to display object highlights on mouse cursor movement
	 * @param  {object} e , browser mouse event
	 * @return null
	 */
	onMouseMove( e ) {
		
		e.stopPropagation()
		if( e.clientX){
			this.room.mouseVector.x = 2 * (e.clientX /  window.innerWidth) - 1;
			this.room.mouseVector.y = 1 - 2 * ( e.clientY / window.innerHeight );
			this.room.ui.outline_object(e);
		}
		
	}

	onTouchMove( e ) {
		if( e.touches[0].clientX){
			this.room.mouseVector.x = 2 * (e.touches[0].clientX /  window.innerWidth) - 1;
			this.room.mouseVector.y = 1 - 2 * ( e.touches[0].clientY / window.innerHeight );
			his.room.ui.outline_object(e);
		}
	}


	/**
	 * method to handle mouse down or touch start
	 * @param {object} e , browser event
	 * @return null
	 */
	onMouseDown( e ) {
		e.stopPropagation()
		if( e.clientX){
			this.room.mouseVector.x = 2 * (e.clientX /  window.innerWidth) - 1;
			this.room.mouseVector.y = 1 - 2 * ( e.clientY / window.innerHeight );
			room.ui.focus_object(e);
		}else if( e.touches){
			this.room.mouseVector.x = 2 * (e.touches[0].clientX /  window.innerWidth) - 1;
			this.room.mouseVector.y = 1 - 2 * (e.touches[0].clientY / window.innerHeight );
			room.ui.focus_object(e);
		}
	}
	/**
	 * method to handle mouse up or touch end events
	 *  @param {object} e , browser event
	 * @return null
	 */
	onMouseUp( e ) {
		

		e.stopPropagation()
		//room.dragPos=0;
		if( e.clientX){
			this.room.mouseVector.x = 2 * (e.clientX /  window.innerWidth) - 1;
			this.room.mouseVector.y = 1 - 2 * ( e.clientY / window.innerHeight );
			//this.room.ui.release_object();
		}else if( e.touches){
		
			this.room.mouseVector.x = 2 * (e.changedTouches[0].clientX /  window.innerWidth) - 1;
			this.room.mouseVector.y = 1 - 2 * ( e.changedTouches[0].clientY / window.innerHeight );
			//this.room.ui.release_object();
		}

	}


	/**
	 * releases controls and events on dragged object
	 * @return null
	 */
	release_object(){

		function triggerMouseEvent (node, eventType) {///to stop orbit controls action
		    var clickEvent = document.createEvent ('MouseEvents');
		    clickEvent.initEvent(eventType, true, true);
		    node.dispatchEvent(clickEvent);
		}

		if( this.room.dragObject ){
			
			this.room.dragObject = null;
			this.room.screenlock=false;
			this.room.controls.enabled = true;
			triggerMouseEvent (document.getElementById("myscene"), "mouseup");
		}
	}
	

	/**
	 * outlines object with effect composer on mouseover or drags object if mousedown event
	 * @param  {object} e, browser event triggered by mouse move. Doesn't work with touch.
	 * @return {[type]}   [description]
	 */
	outline_object(e) {

		if( room.dragObject)return;
		this.room.raycaster.setFromCamera(  this.room.mouseVector, this.room.camera );	
		var j=0;
		var intersects= this.room.raycaster.intersectObjects(this.room.proxys.children);
		var temp = [];
		if( intersects.length> 0){
	//	console.log( intersects)		
			j = intersects.length-1;
			while ( j>= 0){
				if( this.room.ismesh(intersects[j].object)){
				//if( intersects[j].object instanceof THREE.Mesh){
					//var selectedObject = intersects[ j ].object;
					var selectedObjectName = intersects[ j ].object.name.replace("_PROXY","");
					var selectedObject = this.room.scene.getObjectByName(selectedObjectName); 
				
					this.room.addSelectedObject( selectedObject)
					this.room.outs.selectedObjects = room.selectedObjects;
					this.room.outs.enabled = true;
				}
				j--;
			}
		}else{
			//this.room.outs.enabled = false;
			//if( this.room.loading==0){	this.room.outs.selectedObjects = []; }
			//console.log("null")
			//room.dragObject = null;

		}
	}

	/**
	 * outlines object with effect composer on mouseover or drags object if mousedown event
	 * @param  {object} e, browser event triggered by mouse move. Doesn't work with touch.
	 * @return {[type]}   [description]
	 */
	focus_object(e) {
		if( this.room.dragObject){
			let fire = this.room.fire;
			this.room.scene.getObjectByName("Logo").children.forEach(function(v){
		       v.material = fire;
		    });
			this.room.dragObject.position.copy(this.room.reset[0])
			this.room.dragObject.rotation.y=this.room.reset[1].y
			this.room.key_light.position.x=0.0;
      		this.room.key_light.position.z=0.0;

			this.room.dragObject=null;
			this.room.fanOn= true;
			setTimeout(()=>{document.getElementById("focus-node").style.display="none"},300);
			return; 
		}
		this.room.raycaster.setFromCamera(  this.room.mouseVector, this.room.camera );	
		var j=0;
		var intersects= this.room.raycaster.intersectObjects(this.room.proxys.children);
		var temp = [];
		if( intersects.length> 0){
			
			j = intersects.length-1;
			
				if( this.room.ismesh(intersects[0].object)){
				//if( intersects[j].object instanceof THREE.Mesh){
					//var selectedObject = intersects[ j ].object;
					var selectedObjectName = intersects[ 0 ].object.name.replace("_PROXY","");
					var selectedObject = this.room.scene.getObjectByName(selectedObjectName); 
					intersects[ 0 ].object.geometry.computeBoundingBox();
					let bb = intersects[ 0 ].object.geometry.boundingBox.clone(); 
					let spread = 1+ ((((bb.max.x-bb.min.x)/1.5)+ ((bb.max.z-bb.min.z)/1.5) )/2.0)
					
					this.room.reset[0].copy(selectedObject.position)
					this.room.reset[1].y = selectedObject.rotation.y;
					this.room.posepos.copy( getPointInBetweenByLen(new THREE.Vector3(0,1.8,0), selectedObject.position, spread) );
					this.room.dragObject =  selectedObject;
					
				//	this.room.dragObject.position.copy( this.room.posepos)


					this.room.outs.enabled = true;
					this.poplabel(selectedObjectName );
				}
				
			
		}
	}

	poplabel(nom){
		
		this.room.scene.getObjectByName("Logo").children.forEach(function(v){
		//   v.material = wood;
		});
		let wrap = document.getElementById("focus-node");
		if(! wrap){
			let wrap = document.createElement("div");
			wrap.setAttribute("id","focus-node"); 
			let h = document.createElement("h3");
			h.setAttribute("id","focus-title");
			h.innerHTML = nom;
			let s = document.createElement("span");

			s.innerHTML = '3D Modelling by Mr. Nick Andrews.';
		

			let u = document.createElement("ul");
			u.setAttribute("class","contact-me");

			let l0 = document.createElement("li");
			l0.innerHTML= "";
			let a = document.createElement("a");
			let myurl = "https://portfolio.websolutions.to";
			a.setAttribute("href", myurl);
			a.setAttribute("class","portfolio");
			a.setAttribute("target", "_blank");
			
			a.innerHTML= "Work Portfolio";
			l0.appendChild(a)

			let l1 = document.createElement("li");
			l1.innerHTML= "";
			let b = document.createElement("a");
			let myurl1 = "https://github.com/Nick-Andrews";
			b.setAttribute("href", myurl1);
			b.setAttribute("target", "_blank");

			b.innerHTML= "Github";
			l1.appendChild(b);
			

			let l2 = document.createElement("li");
			l2.innerHTML= "";
			let c = document.createElement("a");
			let myurl2 = "https://www.linkedin.com/in/nick-andrews-a9236115/";
			c.setAttribute("href", myurl2);
			c.setAttribute("target", "_blank");

			c.innerHTML= "LinkedIn";
			l2.appendChild(c);

			u.appendChild(l0);
			u.appendChild(l1);
			u.appendChild(l2);

			wrap.appendChild(h);
			wrap.appendChild(s);
			wrap.appendChild(u);
			document.body.appendChild(wrap);
		}else{
			document.getElementById("focus-node").style.display="block";
			document.getElementById("focus-title").innerHTML= nom;
		}
	}
}


export {UI}