import * as dat from 'dat.gui';

let CONTROL = {
	material: function(mat){
	
			var effectController  = {
				repx: mat.map.repeat.x,
				repy: mat.map.repeat.y,
				rot: mat.map.rotation,
				offx: mat.map.offset.x,
				offy: mat.map.offset.y,
				ao: mat.map.aoMapIntensity,
				refractionRatio: mat.refractionRatio,
				metal: mat.metalness,
				rough: mat.roughness,
				emiss: mat.emissiveIntensity,
				bump: mat.bumpScale,
			}
			var gui = new dat.GUI();
			if( mat.metalness){
				effectController.metal = mat.metalness;
				gui.add( effectController, "metal",0, 1, .01 ).onChange( modit );
			
			}
			if( mat.metalness){
				effectController.rough = mat.roughness;
				gui.add( effectController, "rough",0, 1, .01 ).onChange( modit );
			}
			if( mat.emissiveIntensity){
				effectController.emiss = mat.emissiveIntensity;
				gui.add( effectController, "emiss",0, 1, .01 ).onChange( modit );
			}
			if( mat.refractionRatio){
				effectController.refractionRatio = mat.refractionRatio;
				gui.add( effectController, "refractionRatio",0, 1, .01 ).onChange( modit );
			}
			if( mat.bumpScale){
				effectController.bump = mat.bumpScale;
				gui.add( effectController, "bump",0, 1, .01 ).onChange( modit );
			}
			if( mat.aoMapIntensity){
				effectController.ao = mat.aoMapIntensity;
				gui.add( effectController, "ao",0, 10, .01 ).onChange( modit );
			}
			
			gui.add( effectController, "repx", 0, 24, 0.01 ).onChange( modit );
			gui.add( effectController, "repy",0, 24, 0.01 ).onChange( modit );
			gui.add( effectController, "rot",0, 2*Math.PI, 0.01 ).onChange( modit );
			
		//	gui.add( effectController, "offx",0, 1, 0.01 ).onChange( modit );
		//	gui.add( effectController, "offy",0, 1, 0.01 ).onChange( modit );
			
			
			
			
			function modit(){
				mat.map.repeat.x = effectController.repx;
				mat.map.repeat.y = effectController.repy;
				mat.map.rotation = effectController.rot;
				
			//	mat.map.offset.x = effectController.offx;
			//	mat.map.offset.y = effectController.offy;
				mat.refractionRatio = effectController.refractionRatio;
				mat.metalness = effectController.metal;
				mat.roughness = effectController.rough;
				mat.emissiveIntensity = effectController.emiss;
				mat.bumpScale = effectController.bump;
				mat.aoMapIntensity = effectController.ao;
				
					
			}



	},

	light: function(lig){
		var gui = new dat.GUI();
		var effectController  = {
			intensity: lig.intensity,
		}
		if( lig.distance){
			effectController.distance= lig.distance
			gui.add( effectController, "distance", 0, 200, 1 ).onChange( modit );
		}
		if( lig.decay){
			effectController.decay= lig.decay
			gui.add( effectController, "decay", 0, 5, 0.01 ).onChange( modit );
			
		}
		if( lig.position){
			effectController.x= lig.position.x;
			effectController.y= lig.position.y;
			effectController.z= lig.position.z;
			gui.add( effectController, "x", -40, 140, 0.1 ).onChange( modit );
			gui.add( effectController, "y",-40, 140, 0.1 ).onChange( modit );
			gui.add( effectController, "z",-40, 140, 0.1 ).onChange( modit );

		}


		
			gui.add( effectController, "intensity", 0, 5, 0.01 ).onChange( modit );
			
		
			
		function modit(){
			if( lig.intensity)lig.intensity = effectController.intensity
			if( lig.distance)lig.distance = effectController.distance
			if(lig.decay)lig.decay = effectController.decay
			if(lig.position){
				lig.position.set(effectController.x, 
				effectController.y, 
				effectController.z);
			}
			
		}

	},
	shape: function( obj ){
			var effectController  = {
				x: obj.position.x,
				y: obj.position.y,
				z: obj.position.z,
				xr: obj.rotation.x,
				yr: obj.rotation.y,
				zr: obj.rotation.z,
				xs: obj.scale.x,
				ys: obj.scale.y,
				zs: obj.scale.z,
		};


		var gui = new dat.GUI();

		gui.add( effectController, "x", -40, 140, .1 ).onChange( modit );
		gui.add( effectController, "y",-40, 140, .1 ).onChange( modit );
		gui.add( effectController, "z",-40, 140, .1 ).onChange( modit );
		gui.add( effectController, "xr", -2*Math.PI, 2*Math.PI, .1 ).onChange( modit );
		gui.add( effectController, "yr",-2*Math.PI, 2*Math.PI, .1 ).onChange( modit );
		gui.add( effectController, "zr",-2*Math.PI, 2*Math.PI, .1 ).onChange( modit );
		gui.add( effectController, "xs", 0, 100, 1 ).onChange( modit );
		gui.add( effectController, "ys",0, 100, 1 ).onChange( modit );
		gui.add( effectController, "zs",0, 100, 1 ).onChange( modit );

	    function modit(){
			obj.position.set(effectController.x, effectController.y, effectController.z);
			obj.rotation.set(effectController.xr, effectController.yr, effectController.zr);
			obj.scale.set(effectController.xs, effectController.ys, effectController.zs);
		}
	},

	position: function( obj ){
		var effectController  = {
				x: obj.position.x,
				y: obj.position.y,
				z: obj.position.z,
				xr: obj.rotation.x,
				yr: obj.rotation.y,
				zr: obj.rotation.z,
				xs: obj.scale.x,
				ys: obj.scale.y,
				zs: obj.scale.z,
		};

		var gui = new dat.GUI();

		gui.add( effectController, "x", -100.0, 100.0, 0.01 ).onChange( modit );
		gui.add( effectController, "y",0, 40, 0.01 ).onChange( modit );
		gui.add( effectController, "z",-100, 100, 0.01 ).onChange( modit );
		gui.add( effectController, "xr", -2*Math.PI, 2*Math.PI, .1 ).onChange( modit );
		gui.add( effectController, "yr",-2*Math.PI, 2*Math.PI, .1 ).onChange( modit );
		gui.add( effectController, "zr",-2*Math.PI, 2*Math.PI, .1 ).onChange( modit );
		gui.add( effectController, "xs", 0, 100, 1 ).onChange( modit );
		gui.add( effectController, "ys",0, 100, 1 ).onChange( modit );
		gui.add( effectController, "zs",0, 100, 1 ).onChange( modit );

	    function modit(){
			obj.position.set(effectController.x, effectController.y, effectController.z);
			obj.rotation.set(effectController.xr, effectController.yr, effectController.zr);
			obj.scale.set(effectController.xs, effectController.ys, effectController.zs);
		}
	},
}


///https://github.com/mrdoob/three.js/issues/10373
let eventOpts = false;
function nop() {}
try {
	const opts = {};
	Object.defineProperty(opts, 'passive', {
		get () {
			eventOpts = {
				passive: true
			};
		}
	});
	window.addEventListener('test', nop, opts);
	window.removeEventListener('test', nop, opts);
} catch (e) {}






class Preloader{
	constructor(options){
		this.assets = {};
		this.nom=[];
		this.hed =null;
		this.model=0;
		for(let asset of options.assets){
			this.assets[asset] = { loaded:0, complete:false };
			this.load(asset);
		}
		this.container = options.container;
		if (options.onprogress==undefined){
			this.onprogress = onprogress;
			if(! document.getElementById("loading-data")){

				this.hed = document.createElement("header");
				this.hed.setAttribute("class","loading");
				this.hed.setAttribute("id","loading-hed");
				this.hed.innerHTML= "3D Scene assets are presently loading."
				document.body.appendChild(this.hed);	

				this.label= document.createElement("span");
				this.label.setAttribute("id","loading-data");
				this.label.setAttribute("class","loading");
				
				document.body.appendChild(this.label);
			}
			this.domElement = document.createElement("canvas");
			this.domElement.setAttribute("class", "pie");
			this.domElement.setAttribute("height", window.innerHeight+"px");
			this.domElement.setAttribute("width", window.innerWidth+"px");
			this.progressBar = this.domElement;
			if (this.container!=undefined){
				this.container.appendChild(this.domElement);
			}else{
				document.body.appendChild(this.domElement);
			}
		}else{
			this.onprogress = options.onprogress;
		}
		this.oncomplete = options.oncomplete;
		const loader = this;
		


		function onprogress(delta){
			var msg="";
			const progress = delta*(1.5*Math.PI);

		
			switch(this.model){
				case 0:
					msg="The 3D Scene assets are loading.";
					const progress = delta*(-0.25*Math.PI);
					break;
				case 1:
					msg="<br />Thank you for your patience.";
				
					break;
				case 2:
					msg="<br /><br />Getting there soon.";
				
					break;
				case 3:
					msg="Open-Source software credit for this project goes to: <strong>Three.js</strong> and <strong>jQuery.</strong>";
				
					break;
				case 4:
					msg="HDRI HAVEN provided the environment maps.";
					break;
				case 5:
					msg="<br /><br /><br /><sub><em>Nick Andrews</em> <br />Intermediate Web Developer</sub>";
					break;
			}
			document.getElementById("loading-hed").innerHTML=msg;
		
		//	const progress = delta*(1.5*Math.PI);
			var ctx = loader.progressBar.getContext("2d");
			ctx.clearRect(0, 0, loader.progressBar.width, loader.progressBar.height);
		//	ctx.lineWidth = 30;
		//	ctx.strokeStyle = 'rgba('+(delta*255)+',255, 255, 1)';
		//	ctx.beginPath();
		//	ctx.arc(75, 75, 150, Math.PI*-0.5, progress, false);
			let w=window.innerWidth/2
			 
			let h = window.innerHeight/2;
			
			for( var i = 0 ; i < 120; i+=5){
				ctx.beginPath();
				switch(i%4){
					case 0:
						ctx.lineWidth = 2;
						ctx.strokeStyle = 'rgba(61,110,9, 0.5)';
						ctx.arc(w, h, i, Math.PI*-0.5, progress, false);
					break;
					case 1:
						ctx.lineWidth = 2;
						ctx.strokeStyle = 'rgba(97,147,51, 0.4)';
						ctx.arc(w, h, i+i, Math.PI*-0.5, progress, false); 

					break;
					case 2:
						ctx.lineWidth = 2;	
						ctx.strokeStyle = 'rgba(163,207,117, 0.5)';
						ctx.arc(w, h, i+2, Math.PI*-0.5, progress, false);
					break;
					case 3:
						ctx.lineWidth = 1;	
						ctx.strokeStyle = 'rgba(76,102,48, 0.5)';
						ctx.arc(w, h, i+3, Math.PI*-0.5, progress, false);
					break;
					case 4:
						ctx.lineWidth = 1;	
						ctx.strokeStyle = 'rgba(0,102,0,0.5)';
						ctx.arc(w, h, i+4, Math.PI*-0.5, progress, false);
					break;

					
				}
			ctx.stroke()
			}

				
		}
	}
	checkCompleted(){
		for(let prop in this.assets){
			const asset = this.assets[prop];
			if (!asset.complete) return false;
		}

		return true;
	}
	


	get progress(){
		let total = 0;
		let loaded = 0;
		
		for(let prop in this.assets){
			const asset = this.assets[prop];
			if (asset.total == undefined){
				loaded = 0;
				break;
			}
			loaded += asset.loaded; 
			total += asset.total;
			var p = prop.split("/")
			var x = p[p.length-1].split(".")
			if( x[0]=="Cabin_simple"){
				if(loaded>(total*0.85)){
					this.model=5;
				}else if(loaded>(total*0.70)){
					this.model=4;
				}else if(loaded>(total*0.55)){
					this.model=3;
				}else if (loaded>(total*.35)){
					this.model=2;
				}else if (loaded>(total*0.2)){
					this.model=1;
				}
			}

			document.getElementById("loading-data").innerHTML = "loading ["+x[0]+" "+x[1]+"] files <span>"+parseInt((loaded/total)*100)+"%</span>";	
		
		}
		
		return loaded/total;
	}
	
	load(url){
		
		const loader = this;
		var xobj = new XMLHttpRequest();
		xobj.overrideMimeType("application/json");
		xobj.open('GET', url, true); 
		xobj.onreadystatechange = function () {
			  if (xobj.readyState == 4 && xobj.status == "200") {
			 
				  loader.assets[url].complete = true;
				  if (loader.checkCompleted()){
				  	
					  if (loader.domElement!=undefined){
						  if (loader.container!=undefined){
							  loader.container.removeChild(loader.domElement);
						  }else{
							  document.body.removeChild(loader.domElement);
							 
						  }
					  }
					  loader.oncomplete();	
				  }
			  }
		};
		xobj.onprogress = function(e){
			const asset = loader.assets[url];
			asset.loaded = e.loaded;
			asset.total = e.total;
			

			loader.onprogress(loader.progress);
		}
		xobj.send(null);
	}
}




















/**
 * locate new 2D point at an angle from a specified distance
 * @param  {float} x , x origin point
 * @param  {float} y , y origin point  
 * @param  {float} angle, angle in radians
 * @param  {float} distance, specified distance
 * @return {object} , point with x and y properties
 */
function findNewPoint(x, y, angle, distance) {
    var result = {};
    result.x = (Math.cos(angle * Math.PI / 180) * distance + x);
    result.y = (Math.sin(angle * Math.PI / 180) * distance + y);
    return result;
}
function getPointInBetweenByLen(pointA, pointB, length) {
    var dir = pointB.clone().sub(pointA).normalize().multiplyScalar(length);
    return pointA.clone().add(dir);
} 
function distanceVector( v1, v2 ){
    var dx = v1.x - v2.x;
    var dy = v1.y - v2.y;
    var dz = v1.z - v2.z;
    return Math.sqrt( dx * dx + dy * dy + dz * dz );
}

export{  CONTROL, Preloader, eventOpts, findNewPoint, distanceVector, getPointInBetweenByLen }
